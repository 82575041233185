<template>
  <div
    class="masonry-item_type_multipart roll"
  >
    <v-progress-linear 
      indeterminate
      size="50"
      absolute
      top
      v-if="!loaded"
      left
      style="z-index: 2;"
      color="primary"
    />
    <template>
      <div class="roll__items">
        <div
          class="roll__item"
          v-for="(part, index) in parts"
          :key="`part-${part[0]}`"
          :style="{
            opacity: Number(currentPart === index),
            zIndex: Number(currentPart === index)
          }"
        >
          <v-card
            width="100%"
            flat
            height="100%"
            color="transparent"
          >
            <component
              :original-item="part"
              :item-id="part[0]"
              :height="height"
              :loaded="loaded"
              is-multipart
              class="masonry-item-type"
              :item="part[1]"
              :broken-ids="brokenIds"
              :is="part[1].type + 'Type'"
            />
          </v-card>
        </div>
      </div>
      <div 
        class="roll__hover d-flex"
        @mousemove.stop="onMouseMove"
        @mouseleave.stop="onMouseLeave"
      >
        <div 
          class="roll__hover-item"
          v-for="(hoverItem) in parts.length"
          :key="`hover-item-${hoverItem}`"
          :style="{
            width: 100 / parts.length + '%',
            height: height + 'px'
          }"
          :data-index="hoverItem - 1"
        />
      </div>
    </template>
  </div>
</template>
<script>

const imageType = () => import('./image')
const noteType = () => import('./note')
const videoType = () => import('./video')
const quoteType = () => import('./quote')

export default {
  props: {
    loaded: {},
    height: {},
    itemId: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    parts: {
      required: true
    }
  },
  name: 'ItemTypeMultipart',
  components: {
    imageType,
    noteType,
    videoType,
    quoteType
  },
  data: () => ({
    brokenIds: [],
    currentPart: 0
  }),
  async created() {
    this.currentPart = 0
  },
  mounted() {
    // this.$emit('resourceLoaded')
    this.dividers = []
  },
  methods: {
    onMouseMove(e) {
      const { target } = e
      const dataIndex = Number(target.getAttribute('data-index') || null)
      this.currentPart = dataIndex
    },
    onMouseLeave() {
      this.currentPart = 0
    }
  },
  watch: {

  }
}
</script>
<style lang="scss">
.roll {
  position: relative;

  &__items {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__item {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    max-height: 200px;
    z-index: 0;
    transition: opacity 0.3s;
    background: transparent;

  }

  &__hover {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: transparent;

    &-item {
      
    }
  }
}
</style>